import useStatic from 'bootstrap/bind-hook/static'
import userInvalidate from 'request/user/user-invalidate-self'

export default function Faq() {
  const { user, logout } = useCurrent()
  const panel = usePanel()
  const { state, mutate } = useStatic({ openQuestion: null })
  const t = useI18n()
  const router = useRouter()
  const ref = useRef(null)
  useEffect(() => {
    const { q } = router.query
    if (!q) return

    mutate({ openQuestion: q })
    setTimeout(() => {
      const blockPosResolver = () => {
        if (q < 9 || q == 12) return 'start'
        return 'end'
      }

      ref.current.scrollIntoView({ behavior: 'smooth', block: blockPosResolver() })
    }, 1000)
  }, [router])
  const onFaqExpand = (idx, isExpanded) => {
    if (!isExpanded) return
    mkt.qaClick(idx)
  }
  const onDeleteAccount = async() => {
    if (!user) return panel.open('auth')
    if (!confirm(`確定要刪除『${user.name}』這個帳號嗎?`)) return

    await userInvalidate()
    await logout(true)
    $alert(t('panel:accountSetting.invalidateSuccess'))
    h.path.go.root()
  }

  return (
    <div className='container' ref={ ref }>
      <div className='py-5 flex flex-col lg:flex-row lg:py-20'>
        <div className='text-center py-7 text-3xl font-bold flex-1'>{ t('common:intro.faq.title') }</div>
        <div className='flex-1'>
          <C.Accordion items={ faqs({ onDeleteAccount }) } openIdx={ state.openQuestion } onExpand= { (idx, isExpanded) => onFaqExpand(idx, isExpanded) }/>

        </div>
      </div>
    </div>
  )
}

const Title = ({ children, className }) => (<div className={ cn('font-medium pb-2', className) }>{ children }</div>)
const Text = ({ children, className }) => (<div className={ cn('font-light', className) }>{children}</div>)
const Photo = ({ className, src }) => (<C.Image className={ cn('shadow cursor-pointer', className) } type='contain' src={ src } onClick={ () => h.$singlePhoto(src) }/>)
const faqs = ({ onDeleteAccount }) => [
  {
    title: 'Q1. 如何挑選適合自己的設計師',
    description: (
      <>
        <div className='font-light'>
          以往多數人挑選設計師的方式，不外乎是朋友推薦、網路搜尋等方式，設計師資訊非常零碎、不透明，就算找到一些參考資料，也不容易驗證真實性。<br/>
          <br/>
          StyleMap美配的設計師推薦，皆以真實的熱門度為主，你可以依序向下找即可。看到感興趣的設計師，除了可以進入設計師的個人頁面觀看描述，作品的風格與評價狀況都是很重要的線索。建議你選擇已具有金色徽章<C.Image src={ cdn('images/badge/guarantee64.png') } className='mx-1 h-4 w-4 text-gray-900 inline-block translate-y-[2px]'/>的設計師，代表<span className='font-normal'>服務品質</span>及<span className='font-normal'>顧客回購率</span>皆已通過平台認證。
        </div>
        <div className='py-3'>
          <Photo className='pt-[68%]' src={ cdn('faq/ranking2.jpg') } />
        </div>
      </>
    )
  },
  {
    title: 'Q2. 美配如何把關您看到的所有資訊',
    description: (
      <>
        <Title>設計師作品集</Title>
        <Text>在美配上面設計師可以自行上傳個人的作品集，同時美配設有檢舉機制，確保設計師的作品皆是本人實際服務的成果，若有同業或是消費者發現非設計師本人作品，可提供證據向美配檢舉，設計師會被停權警告，較嚴重者甚至會被停權警告，較嚴重者甚至會被美配刪除帳號。</Text>
        <div className='py-3'>
          <Photo className='pt-[57%]' src={ cdn('faq/post.jpg') } />
        </div>
        <Title className='pt-8'>設計師評價</Title>
        <Text>要在美配上面撰寫設計師評論，必須先實際預約設計師，並到店用美配完成付款，才可對設計師進行評價。評價中會顯示該次消費的項目，因此在挑選設計師時，建議可以先看看該設計師在美配上的實際口碑如何，以及來自哪種服務項目。</Text>
        <div className='py-3'>
          <Photo className='pt-[65%]' src={ cdn('faq/review.jpg') } />
        </div>
        <Title className='pt-8'>設計師班表</Title>
        <Text>美配上面的設計師班表，如果實際有消費者預約，時間會顯示灰色，因此可以清楚知道該設計師目前實際的預約狀況如何。</Text>
        <div className='py-3'>
          <Photo className='pt-[73%]' src={ cdn('faq/schedule.jpg') } />
        </div>
        <Title className='pt-8'>設計師服務價位、使用產品</Title>
        <Text>點選『預約』設計師，即可看到該設計師所有提供的服務項目，以及收費方式、使用的髮品品牌、優惠活動，讓你盡可能在預約之前，就能清楚服務內容與收費方式。</Text>
        <div className='py-3'>
          <Photo className='pt-[75%]' src={ cdn('faq/price.jpg') } />
        </div>
        <Title className='pt-8'>設計師親自解惑</Title>
        <Text>若對於設計師的作品心動，但不太確定適不適合自己，可以直接在平台上點『諮詢』設計師，直接告知你的狀況與需求，透過實際的諮詢，也可以感受一下設計師在溝通上的專業度是否符合你的期待，並且可以放心把頭髮交給他處理。</Text>
        <div className='py-3'>
          <Photo className='pt-[58%]' src={ cdn('faq/message.jpg') } />
        </div>
      </>
    )
  },
  {
    title: 'Q3. 善用『開始搜尋』',
    description: (
      <>
        <Text>
          畫面最上方有個『開始搜尋』的按鈕，點開後會有快速引導的入口，如果已經有好奇的髮型，可以在輸入框試著打字看看。<br/>
        </Text>
        <div className='py-3'>
          <Photo className='pt-[61.25%]' src={ cdn('faq/search5.jpg') } />
        </div>
        <Text>裡面的『找服務』、『找設計師』、『找髮型』等入口應該是蠻直覺的，如果不確定的話就點點看～</Text>
        <div className='font-medium pt-5 pb-1'>＊小提醒</div>
        <Text>
          1. 設計師或店家的搜尋結果，若有出現金色徽章<C.Image src={ cdn('images/badge/guarantee64.png') } className='mx-1 h-4 w-4 text-gray-900 inline-block translate-y-[2px]'/>，代表<span className='font-normal'>服務品質</span>及<span className='font-normal'>顧客回購率</span>皆已通過平台認證。<br/>
          2. 設計師或店家若沒有顯示徽章，則是一般店家，有比較高的機率遇到服務品質不穩定的狀況，請務必在預約前做好諮詢，降低踩雷的機率。
        </Text>
      </>
    )
  },
  {
    title: 'Q4. 善用『過濾功能』',
    description: (
      <>
        <Text>
          只要進入『找服務』、『找設計師』或『找髮型』頁面，上方都會出現一些篩選功能，縣市、鄉鎮還算直覺，按下去就只會出現該區的設計師或作品，髮長的部份記得按按看，挑選的圖片的會變成選定的類型喔，例如變成男生的作品。
        </Text>
        <div className='py-3'>
          <Photo className='pt-[68%]' src={ cdn('faq/ranking2.jpg') } />
        </div>
      </>
    )
  },
  {
    title: 'Q5. 怎麼找到適合的服務',
    description: (
      <>
        <div className='font-light'>
          建議你選擇已具有金色徽章<C.Image src={ cdn('images/badge/guarantee64.png') } className='mx-1 h-4 w-4 text-gray-900 inline-block translate-y-[2px]'/>的設計師，代表<span className='font-normal'>服務品質</span>及<span className='font-normal'>顧客回購率</span>皆已通過平台認證。
        </div>
        <div className='py-3'>
          <Photo className='pt-[68%]' src={ cdn('faq/ranking2.jpg') } />
        </div>
      </>
    )
  },
  {
    title: 'Q6. 怎麼進行預約',
    description: (
      <>
        <Text>
          活動頁與設計師個人頁都有預約的按鈕，點下去並照著引導的流程完成預約即可，預約完成後會有私訊的通知，請在指定的時間前往髮廊完成服務。
        </Text>
        <div className='py-3'>
          <Photo className='pt-[79%]' src={ cdn('faq/book1.jpg') } />
        </div>
      </>
    )
  },
  {
    title: 'Q7. 怎麼取消預約',
    description: (
      <Text>
        • 取消前應先知道，設計師最寶貴的資源即是時間成本，雖然服務尚未開始，但取消後，空下來的時間也不一定來得及接新的客人，設計師可能因此<span className='font-normal'>損失了服務其它客人的機會</span>。<br/><br/>
        • 預約完成後，可以在對話框裡看見預約紀錄，服務開始前4小時可以免費取消，2~4小時內取消，須支付訂金的30%，2小時內取消，須支付訂金的60%，時間到了未取消，須支付訂金的100%。<br/><br/>
        • 4小時內取消，由於會產生違約金，建議直接<span className='font-normal'>通知設計師另約時間</span>或是<span className='font-normal'>請設計師取消預約</span>，將訂金保留到下一次預約或消費使用。
      </Text>
    )
  },
  {
    title: 'Q8. 什麼是『精選活動專區』',
    description: (
      <>
        <Text>
          不論新秀或是資深設計師，在成長突破的過程時常會需要徵求用戶體驗，可能是累積作品、優化服務流程、進修新技術等等。
          <br/><br/>在精選活動專區裡募集用戶體驗的店家跟設計師皆經過美配平台嚴格把關，除了提供您超值合作優惠，現場亦可針對您的需求進行客製化調整，在體驗服務後您會收到匿名問卷，只要填寫您的真實回饋，對於設計師的成長就會很有幫助喔！
          <br/><br/>點進專區裡的店家，可以查看活動詳情，包含需配合的事項、享有的專屬優惠等，希望藉由體驗讓彼此都能變得更好。
          <br/><br/>＊網頁最上方有個『開始搜尋』的按鈕，可從開始搜尋點擊『找服務』進入活動專區喔～
        </Text>
        <div className='py-3'>
          <Photo className='pt-[61.2%]' src={ cdn('faq/event6.jpg') } />
        </div>
      </>
    )
  },
  {
    title: 'Q9. 你知道註冊有機會獲得100元回饋金嗎',
    description: (
      <Text>
        如果你有推薦人的話，註冊可以獲得100元的回饋金，但是要去哪裡找推薦人呢？沒關系，美配挺你，讓美配成為你的推薦人吧 → <C.Link href='https://style-map.com/referral/9390359' blank>https://style-map.com/referral/9390359</C.Link>
      </Text>
    )
  },
  {
    title: 'Q10. 推薦朋友，你會再有100元回饋金',
    description: (
      <Text>
        如果你使用平台還算愉快順利的話，那你可以分享給你的朋友知道，只要好友成功完成首次線上付款，你可以再獲得一次100元回饋金 (每人最多5次) → <C.Link href='https://style-map.com/referral' blank>前往推薦人頁面</C.Link>
      </Text>
    )
  },
  {
    title: 'Q11. 回饋金的使用方式',
    description: (
      <div className='font-light'>
        回饋金可以折抵該次服務金額的5%，在每次服務完成時自動計算，沒有使用完的部份會保留至下次服務繼續使用。<br/><br/>
        ＊具有金色徽章<C.Image src={ cdn('images/badge/guarantee64.png') } className='mx-1 h-4 w-4 text-gray-900 inline-block translate-y-[2px]'/>的設計師或店家皆已通過平台認證，支援使用回饋金，若您想預約的店家或設計師，尚未具有『金色徽章』，建議使用前先與設計師確認是否支援使用回饋金。
      </div>
    )
  },
  {
    title: 'Q12. 現場如何付款',
    description: (
      <div>
        <div>1. 到店完成服務後，設計師會將該次消費項目跟收費輸入系統。</div>
        <div className='py-1'>2. 你會立即在網頁或APP上收到消費明細，確認無誤後，即可一鍵完成付款。</div>
        <div className='py-3'>
          <Photo className='pt-[80%]' src={ cdn('faq/checkout.jpg') } />
        </div>
        <Text className='py-5'>＊美配支援多元付款方式如： ATM 轉帳、儲值金、信用卡、簽帳金融卡、Apple Pay、Google Pay、LINE Pay、全盈+Pay、零卡分期。</Text>
        <Title className='pt-5'>如何使用零卡分期</Title>
        <Text className='pb-1'>請先加入零卡分期會員，申請步驟如下：</Text>
        <div className='py-3'>
          <Photo className='pt-[164%]' src={ cdn('reward_dm/zero_card_3.jpg') } />
        </div>
        <div className='py-3'>
          <C.Link className='text-red-600 px-1' href='https://0card.cc/2105vendor42716506' target='_blank' rel='noreferrer'>＊點此申請零卡分期會員</C.Link>
        </div>
      </div>
    )
  },
  {
    title: 'Q13. 如何刪除帳號',
    description: (
      <div>
        <div>刪除帳號會永久刪除所有您使用此帳號產生的資訊，並且無法復原。請在刪除前務必確認此帳號沒有任何您需要保存的資料。</div>
        <div className='py-3'>
          <div className='text-red-600 px-1 underline cursor-pointer' onClick={ onDeleteAccount }>點此刪除我的帳號</div>
        </div>
      </div>
    )
  }
]
